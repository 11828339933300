import axios from "@/http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  fetchCustomersList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      if (data) {
        Object.keys(data).forEach((key: string) => {
          formData.append(key, data[key]);
        });
      }

      commit("SET_LOADING", true);
      try {
        const response = await axios.get("/api/v1/admin/customers/", {
          params: data || {},
        });
        if (data.download == 1) {
          resolve(response.data);
        } else {
          const json = response.data;

          if (json.response == 1001) {
            commit("setCustomers", json.customers);
            commit("setCustomersFilters", json.filters);
            resolve(response.data);
          } else {
            reject(response.data.message);
          }
        }
      } catch (e) {
        try {
          reject(e.response.data.message);
        } catch (e) {
          reject(e.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  searchCustomersList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      if (data) {
        Object.keys(data).forEach((key: string) => {
          formData.append(key, data[key]);
        });
      }

      commit("SET_LOADING", true);
      try {
        const response = await axios.get("/api/v1/admin/customers/search/", {
          params: data || {},
        });
        if (data.download == 1) {
          resolve(response.data);
        } else {
          const json = response.data;

          if (json.response == 1001) {
            commit("setCustomerSearchResults", json.customers);
            resolve(response.data);
          } else {
            reject(response.data.message);
          }
        }
      } catch (e) {
        try {
          reject(e.response.data.message);
        } catch (e) {
          reject(e.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  addNewCustomer({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      if (data) {
        Object.keys(data).forEach((key: string) => {
          formData.append(key, data[key]);
        });
      }

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          "/api/v1/admin/customers/add/",
          formData
        );
        const json = response.data;

        if (json.response == 1001) {
          resolve(response.data);
        } else {
          reject(response.data.message);
        }
      } catch (e) {
        try {
          reject(e.response.data.message);
        } catch (e) {
          reject(e.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  checkEmail({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      if (data) {
        Object.keys(data).forEach((key: string) => {
          formData.append(key, data[key]);
        });
      }
      try {
        const response = await axios.post(
          "/api/v1/admin/customers/check-email/",
          formData
        );
        const json = response.data;

        if (json.response == 1001 && json.status == true) {
          resolve(true);
        } else {
          resolve(false);
        }
      } catch (e) {
        resolve(false);
      }
    });
  },
};
const customersStore: Module<any, any> = {
  state: () => ({
    customers: [],
    customersSearch: [],
    customersFilters: {},
  }),
  mutations: {
    setCustomers(state, customers) {
      state.customers = customers;
    },
    setCustomerSearchResults(state, customers) {
      state.customersSearch = customers;
    },
    setCustomersFilters(state, filters) {
      state.customersFilters = filters;
    },
  },
  actions,
  getters: {
    customers(state) {
      return state.customers;
    },
    customersSearch(state) {
      return state.customersSearch;
    },
    customersFilters(state) {
      return state.customersFilters;
    },
  },
};

export default customersStore;
