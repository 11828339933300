import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import store from "@/store";
import http from "@/http/http";

const baseUrl = "http://vps-d5b18cef.vps.ovh.net:2003";
const config: AxiosRequestConfig = {
  baseURL: baseUrl,
};

const auth = axios.create(config);

export default auth;
