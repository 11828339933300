import axios from "@/http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  fetchWalletHistory({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      if (data) {
        Object.keys(data).forEach((key: string) => {
          formData.append(key, data[key]);
        });
      }
      commit("SET_LOADING", true);
      try {
        const response = await axios.get("/api/v1/wallet-history/list/", {
          params: data || {},
        });
        const json = response.data;

        if (json.response == 1001) {
          commit("setWalletHistory", json.wallets);
          commit("setWalletHistoryFilters", json.filters);
          resolve(response.data);
        } else {
          reject(response.data.message);
        }
      } catch (e) {
        try {
          reject(e.response.data.message);
        } catch (e) {
          reject(e.message);
        }
      }

      commit("SET_LOADING", false);
    });
  },
};
const walletStore: Module<any, any> = {
  state: () => ({
    walletHistory: [],
    walletHistoryFilters: {},
  }),
  mutations: {
    setWalletHistory(state, walletHistory) {
      state.walletHistory = walletHistory;
    },
    setWalletHistoryFilters(state, filters) {
      state.walletHistoryFilters = filters;
    },
  },
  actions,
  getters: {
    walletHistory(state) {
      return state.walletHistory;
    },
    walletHistoryFilters(state) {
      return state.walletHistoryFilters;
    },
  },
};

export default walletStore;
