import axios from "@/http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  fetchInventoryList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      if (data) {
        Object.keys(data).forEach((key: string) => {
          formData.append(key, data[key]);
        });
      }

      commit("SET_LOADING", true);
      try {
        const response = await axios.get("/api/v1/admin/inventory/list/", {
          params: data || {},
        });
        const json = response.data;

        if (json.response == 1001) {
          console.log(json.filters);
          commit("setInventory", json.content);
          commit("setInventoryFilters", json.filters);
          resolve(response.data);
        } else {
          reject(response.data.message);
        }
      } catch (e) {
        try {
          reject(e.response.data.message);
        } catch (e) {
          reject(e.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  inventoryStepOne({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      if (data) {
        Object.keys(data).forEach((key: string) => {
          formData.append(key, data[key]);
        });
      }

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          "/api/v1/admin/inventory/add/step-one/",
          formData
        );
        const json = response.data;

        if (json.response == 1001) {
          resolve(response.data);
        } else {
          reject(response.data.message);
        }
      } catch (e) {
        try {
          reject(e.response.data.message);
        } catch (e) {
          reject(e.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  inventoryStepTwo({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      if (data) {
        Object.keys(data).forEach((key: string) => {
          formData.append(key, data[key]);
        });
      }

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          "/api/v1/admin/inventory/add/step-two/",
          formData
        );
        const json = response.data;

        if (json.response == 1001) {
          resolve(response.data);
        } else {
          reject(response.data.message);
        }
      } catch (e) {
        try {
          reject(e.response.data.message);
        } catch (e) {
          reject(e.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  inventoryStepThree({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      if (data) {
        Object.keys(data).forEach((key: string) => {
          formData.append(key, data[key]);
        });
      }

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          "/api/v1/admin/inventory/add/step-three/",
          formData
        );
        const json = response.data;

        if (json.response == 1001) {
          resolve(response.data);
        } else {
          reject(response.data.message);
        }
      } catch (e) {
        try {
          reject(e.response.data.message);
        } catch (e) {
          reject(e.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
};
const inventoryStore: Module<any, any> = {
  state: () => ({
    inventory: [],
    inventoryFilters: {},
  }),
  mutations: {
    setInventory(state, inventory) {
      state.inventory = inventory;
    },
    setInventoryFilters(state, filters) {
      state.inventoryFilters = filters;
    },
  },
  actions,
  getters: {
    inventory(state) {
      return state.inventory;
    },
    inventoryFilters(state) {
      return state.inventoryFilters;
    },
  },
};

export default inventoryStore;
