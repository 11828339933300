<template>
  <div class="bg-gray-50">
    <router-view />
    <l-loading></l-loading>
  </div>
</template>
<script>
import LLoading from "@/components/custom/loading/LLoading";
export default {
  components: { LLoading },
  created() {
    this.$store.dispatch("checkLoginStatus");
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
