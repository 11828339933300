import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import i18n from "./i18n";

import createFilters from "./filters/filters";

import "./styles/tailwind.css";
import "./styles/pagination.css";

import Toast, { PluginOptions, POSITION } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const app = createApp(App);

createFilters(app);

const options: PluginOptions = {
  position: POSITION.TOP_CENTER,
};
app.use(Toast, options);

app.use(i18n).use(store).use(router).mount("#app");
