import axios from "@/http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  fetchProductsList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      if (data) {
        Object.keys(data).forEach((key: string) => {
          formData.append(key, data[key]);
        });
      }
      commit("SET_LOADING", true);
      try {
        const response = await axios.get("/api/v1/admin/products/", {
          params: data || {},
        });
        const json = response.data;

        if (json.response == 1001) {
          commit("setProducts", json.products);
          commit("setProductFilters", json.filters);
          resolve(response.data);
        } else {
          reject(response.data.message);
        }
      } catch (e) {
        try {
          reject(e.response.data.message);
        } catch (e) {
          reject(e.message);
        }
      }

      commit("SET_LOADING", false);
    });
  },
  fetchProduct({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      if (data) {
        Object.keys(data).forEach((key: string) => {
          formData.append(key, data[key]);
        });
      }

      commit("SET_LOADING", true);
      try {
        const response = await axios.get("/api/v1/admin/products/detail/", {
          params: data || {},
        });
        const json = response.data;

        if (json.response == 1001) {
          resolve(response.data);
        } else {
          reject(response.data.message);
        }
      } catch (e) {
        try {
          reject(e.response.data.message);
        } catch (e) {
          reject(e.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  addNewProduct({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      if (data) {
        Object.keys(data).forEach((key: string) => {
          formData.append(key, data[key]);
        });
      }

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          "/api/v1/admin/products/add/",
          formData
        );
        const json = response.data;

        if (json.response == 1001) {
          resolve(response.data);
        } else {
          reject(response.data.message);
        }
      } catch (e) {
        try {
          reject(e.response.data.message);
        } catch (e) {
          reject(e.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  updateProduct({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      if (data) {
        Object.keys(data).forEach((key: string) => {
          formData.append(key, data[key]);
        });
      }
      commit("SET_LOADING", true);
      try {
        const response = await axios.put(
          "/api/v1/admin/products/update/",
          formData
        );
        const json = response.data;

        if (json.response == 1001) {
          resolve(response.data);
        } else {
          reject(response.data.message);
        }
      } catch (e) {
        try {
          reject(e.response.data.message);
        } catch (e) {
          reject(e.message);
        }
      }

      commit("SET_LOADING", false);
    });
  },
  addNewDenomination({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      if (data) {
        Object.keys(data).forEach((key: string) => {
          formData.append(key, data[key]);
        });
      }

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          "/api/v1/admin/products/add-denomination/",
          formData
        );
        const json = response.data;

        if (json.response == 1001) {
          resolve(response.data);
        } else {
          reject(response.data.message);
        }
      } catch (e) {
        try {
          reject(e.response.data.message);
        } catch (e) {
          reject(e.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  updateDenomination({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      if (data) {
        Object.keys(data).forEach((key: string) => {
          formData.append(key, data[key]);
        });
      }

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          "/api/v1/admin/products/update-denomination/",
          formData
        );
        const json = response.data;

        if (json.response == 1001) {
          resolve(response.data);
        } else {
          reject(response.data.message);
        }
      } catch (e) {
        try {
          reject(e.response.data.message);
        } catch (e) {
          reject(e.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
};
const productsStore: Module<any, any> = {
  state: () => ({
    products: [],
    productsFilters: {},
  }),
  mutations: {
    setProducts(state, products) {
      state.products = products;
    },
    setProductFilters(state, filters) {
      state.productsFilters = filters;
    },
  },
  actions,
  getters: {
    products(state) {
      return state.products;
    },
    productsFilters(state) {
      return state.productsFilters;
    },
  },
};

export default productsStore;
