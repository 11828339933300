import axios from "@/http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  fetchSalesReportsList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      if (data) {
        Object.keys(data).forEach((key: string) => {
          formData.append(key, data[key]);
        });
      }

      commit("SET_LOADING", true);
      try {
        const response = await axios.get("/api/v1/reports/sales-report/", {
          params: data || {},
        });
        if (data.download == 1) {
          resolve(response.data);
        } else {
          const json = response.data;

          if (json.response == 1001) {
            commit("setSalesReportRaw", json);
            commit("setSalesReport", json.reports);
            commit("setSalesReportFilters", json.filters);
            resolve(response.data);
          } else {
            reject(response.data.message);
          }
        }
      } catch (e) {
        try {
          reject(e.response.data.message);
        } catch (e) {
          reject(e.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  fetchRechargeSummaryReportList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      if (data) {
        Object.keys(data).forEach((key: string) => {
          formData.append(key, data[key]);
        });
      }

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          "/api/v1/admin/recharge-summary-report/",
          { params: data || {} }
        );
        if (data.download == 1) {
          resolve(response.data);
        } else {
          const json = response.data;

          if (json.response == 1001) {
            commit("rechargeSummaryReportRaw", json);
            commit("rechargeSummaryReport", json.records);
            commit("rechargeSummaryReportFilters", json.filters);
            commit(
              "setTransactionModes",
              json.type_of_filters.transaction_modes
            );
            resolve(response.data);
          } else {
            reject(response.data.message);
          }
        }
      } catch (e) {
        try {
          reject(e.response.data.message);
        } catch (e) {
          reject(e.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
};
const reportsStore: Module<any, any> = {
  state: () => ({
    salesReportRaw: {},
    salesReport: [],
    salesReportFilters: {},
    rechargeSummaryReportRaw: {},
    rechargeSummaryReport: [],
    rechargeSummaryFilters: {},
    transactionModes: [],
  }),
  mutations: {
    setSalesReportRaw(state, salesReportRaw) {
      state.salesReportRaw = salesReportRaw;
    },
    setSalesReport(state, salesReport) {
      state.salesReport = salesReport;
    },
    setSalesReportFilters(state, filters) {
      state.salesReportFilters = filters;
    },
    rechargeSummaryReportRaw(state, salesReportRaw) {
      state.rechargeSummaryReportRaw = salesReportRaw;
    },
    rechargeSummaryReport(state, salesReport) {
      state.rechargeSummaryReport = salesReport;
    },
    rechargeSummaryReportFilters(state, filters) {
      state.rechargeSummaryFilters = filters;
    },
    setTransactionModes(state, filters) {
      state.transactionModes = filters;
    },
  },
  actions,
  getters: {
    salesReportRaw(state) {
      return state.salesReportRaw;
    },
    salesReport(state) {
      return state.salesReport;
    },
    salesReportFilters(state) {
      return state.salesReportFilters;
    },
    rechargeSummaryReport(state) {
      return state.rechargeSummaryReport;
    },
    rechargeSummaryReportRaw(state) {
      return state.rechargeSummaryReportRaw;
    },
    rechargeSummaryFilters(state) {
      return state.rechargeSummaryFilters;
    },
    transactionModes(state) {
      return state.transactionModes;
    },
  },
};

export default reportsStore;
