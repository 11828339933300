import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import router from "@/router";
import store from "@/store/index";

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
      ...config.headers,
    };
  }

  return config;
};
const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  try {
    if (error?.response?.status == 401) {
      store.dispatch("logout");
    }
  } catch (e) {
    return Promise.reject(error);
  }
  return Promise.reject(error);
};

const baseUrl = "http://vps-d5b18cef.vps.ovh.net:2003";
const config: AxiosRequestConfig = {
  baseURL: baseUrl,
};

const http = axios.create(config);

http.interceptors.request.use(onRequest, onRequestError);

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

http.interceptors.response.use(onResponse, onRequestError);

export default http;
