{
  "PJTelesoftB2C": "PJ Telesoft B2C",
  "copyright": "Copyright © 2021",
  "home": "Home",
  "welcomeBack": "Welcome Back",
  "enterEmail": "Enter your Email-Address",
  "resetPassword": "Reset Password",
  "login": "Login",
  "register": "Register",
  "loginSuccessful": "Login Successful",
  "invalidUsername": "Invalid Username",
  "invalidPassword": "Invalid Password",
  "balance": "Balance",
  "credit": "Credit",
  "product": "Product",
  "products": "Products",
  "supplier": "Supplier",
  "suppliers": "Suppliers",
  "inventory": "Inventory",
  "customer": "Customer",
  "customers": "Customers",
  "searchCustomer": "Search Customer",
  "wallet": "Wallet",
  "reports": "Reports",
  "reclaim": "Reclaim",
  "reclamation": "Reclamation",
  "addProduct": "Add Product",
  "editProduct": "Edit Product",
  "addNewProduct": "Add New Product",
  "logo": "Logo",
  "productName": "Product Name",
  "pinLength": "Pin Length",
  "instructions": "Instructions",
  "customerCareNumber": "Customer Care Number",
  "productType": "Product Type",
  "purchaseType": "Purchase Type",
  "denomination": "Denomination | Denominations",
  "action": "Action | Actions",
  "addProductImage": "Add Product Image",
  "clear": "Clear",
  "submit": "Submit",
  "supplierName": "Supplier Name",
  "addSupplier": "Add Supplier",
  "editSupplier": "Edit Supplier",
  "addNewSupplier": "Add New Supplier",
  "supplierCompany": "Supplier Company",
  "mobileNumber": "Mobile Number",
  "status": "Status",
  "active": "Active",
  "inActive": "In Active",
  "edit": "Edit",
  "deactivate": "Deactivate",
  "activate": "Activate",
  "fullName": "Full Name",
  "company": "Company",
  "address": "Address",
  "emailId": "Email ID",
  "mobile": "Mobile",
  "stNr": "St. Nr",
  "telephone": "Telephone",
  "ustIdr": "Ust.Idr",
  "city": "City",
  "country": "Country",
  "postalCode": "Postal Code",
  "remark": "Remark | Remarks",
  "update": "Update",
  "cancel": "Cancel",
  "addDenomination": "Add Denomination",
  "updateDenomination": "Update Denomination",
  "description": "Description",
  "add": "Add",
  "noDenominations": "No Denominations",
  "cashCard": "Cash Card | Cash Cards",
  "addInventory": "Add Inventory",
  "image": "Image",
  "availStock": "Avail Stock",
  "delimiter": "Delimiter",
  "next": "Next",
  "select": "Select",
  "batchSequence": "Batch Sequence",
  "pinSequence": "Pin Sequence",
  "buyingDiscount": "Buying Discount",
  "valid": "Valid",
  "invalid": "Invalid",
  "pin": "pin | pins",
  "done": "Done",
  "id": "ID",
  "street": "Street",
  "houseNo": "House No",
  "download": "Download",
  "addCustomer": "Add Customer",
  "editCustomer": "Edit Customer",
  "addNewCustomer": "Add New Customer",
  "firstName": "First Name",
  "lastName": "Last Name",
  "password": "Password",
  "passwordConfirmation": "Password Confirmation",
  "amount": "Amount",
  "addBalance": "Add Balance",
  "fromDate": "From Date",
  "toDate": "To Date",
  "filter": "Filter",
  "clearAll": "Clear All",
  "soldDate": "Sold Date",
  "quantity": "Quantity",
  "buyingPrice": "Buying Price",
  "profit": "Profit",
  "sellingPrice": "Selling Price",
  "totalBuyingAmount": "Total Buying Amount",
  "totalSaleAmount": "Total Sale Amount",
  "showDetails": "Show Details",
  "salesReport": "Sales Report",
  "total": "Total",
  "rechargeSummary": "Recharge Summary",
  "cash": "Cash",
  "transactionMode": "Transaction Mode",
  "date": "Date",
  "created": "Created",
  "lastUpdated": "Last Updated",
  "new": "New",
  "in-progress": "In Progress",
  "issue credit note": "Issue Credit Note",
  "reject": "Reject",
  "logout": "Logout"

}
