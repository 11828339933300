import axios from "@/http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  fetchReclamationList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      if (data) {
        Object.keys(data).forEach((key: string) => {
          formData.append(key, data[key]);
        });
      }

      commit("SET_LOADING", true);
      try {
        const response = await axios.get("/api/v1/admin/reclamations/", {
          params: data || {},
        });
        if (data.download == 1) {
          resolve(response.data);
        } else {
          const json = response.data;

          if (json.response == 1001) {
            commit("setReclamationsRaw", json);
            commit("setReclamations", json.reclamations);
            commit("setSalesReportFilters", json.filters);
            commit("setReclamationStatus", json.type_of_filters.status);
            resolve(response.data);
          } else {
            reject(response.data.message);
          }
        }
      } catch (e) {
        try {
          reject(e.response.data.message);
        } catch (e) {
          reject(e.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
};
const reclamationStore: Module<any, any> = {
  state: () => ({
    reclamationsRaw: {},
    reclamations: [],
    reclamationsFilters: {},
    statusList: [],
  }),
  mutations: {
    setReclamationsRaw(state, reclamationsRaw) {
      state.reclamationsRaw = reclamationsRaw;
    },
    setReclamations(state, salesReport) {
      state.reclamations = salesReport;
    },
    setReclamationsFilters(state, filters) {
      state.reclamationsFilters = filters;
    },
    setReclamationStatus(state, filters) {
      state.statusList = filters;
    },
  },
  actions,
  getters: {
    reclamationsRaw(state) {
      return state.reclamationsRaw;
    },
    reclamations(state) {
      return state.reclamations;
    },
    reclamationsFilters(state) {
      return state.reclamationsFilters;
    },
    statusList(state) {
      return state.statusList;
    },
  },
};

export default reclamationStore;
