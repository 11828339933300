import { createStore } from "vuex";
import authStore from "@/store/auth/authStore";
import productsStore from "@/store/products/productsStore";
import suppliersStore from "@/store/suppliers/suppliersStore";
import inventoryStore from "@/store/inventory/inventoryStore";
import customersStore from "@/store/customers/customersStore";
import walletStore from "@/store/wallet/walletStore.ts";
import reportsStore from "@/store/reports/reportsStore.ts";
import reclamationStore from "@/store/reclamation/reclamationStore.ts";
import router from "@/router";

export default createStore({
  state: {
    isLoggedIn: false,
    isVolunteer: false,
    isLoading: false,
    activeUser: {},
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.isLoggedIn = value;
    },
    SET_LOADING(state, value) {
      state.isLoading = value;
    },
    SET_USER_DATA(state) {
      try {
        const user = localStorage.getItem("user");
        const json = JSON.parse(user || "{}");
        if (json) {
          state.isVolunteer = json.role == "volunteer";
        }
        state.activeUser = json;
      } catch (e) {
        state.activeUser = {};
      }
    },
  },
  getters: {
    isLoggedIn(state) {
      return state.isLoggedIn;
    },
    isLoading(state) {
      return state.isLoading;
    },
    isVolunteer(state) {
      return state.isVolunteer;
    },
    activeUser(state) {
      return state.activeUser;
    },
  },
  actions: {
    checkLoginStatus({ commit }) {
      const token = localStorage.getItem("token");
      if (token) {
        commit("SET_LOGGED_IN", true);
        commit("SET_USER_DATA");
      } else {
        commit("SET_LOGGED_IN", false);
        commit("SET_USER_DATA");
      }
    },
    logout({ commit }) {
      localStorage.clear();
      commit("SET_LOGGED_IN", false);
      commit("SET_USER_DATA");
      router.push("/login");
    },
  },
  modules: {
    authStore,
    productsStore,
    suppliersStore,
    inventoryStore,
    customersStore,
    walletStore,
    reportsStore,
    reclamationStore,
  },
});
