import axios from "@/http/auth";
import http from "@/http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  performLogin({ commit, dispatch }, loginDetails: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const fingerprint = _getFingerprint();
      loginDetails.admin_panel = "1";
      loginDetails.hash = fingerprint;
      loginDetails.recaptcha_token = "";

      const formData = new FormData();

      Object.keys(loginDetails).forEach((key: string) => {
        formData.append(key, loginDetails[key]);
      });

      try {
        const response = await axios.post("/api/v1/login/", formData);
        commit("SET_LOGGED_IN", true);
        const json = response.data;
        commit("saveToken", json.token);
        commit("saveRefreshToken", json.refresh);
        commit("saveUser", JSON.stringify(response.data));
        resolve(response.data);
      } catch (e) {
        try {
          reject(e.response.data.message);
        } catch (e) {
          reject(e.message);
        }
      }
    });
  },
};
const authStore: Module<any, any> = {
  state: () => ({}),
  mutations: {
    saveToken(state, token) {
      localStorage.setItem("token", token);
    },
    saveRefreshToken(state, token) {
      localStorage.setItem("refresh", token);
    },
    saveUser(state, user) {
      localStorage.setItem("user", user);
    },
  },
  actions,
  getters: {},
};

export default authStore;

function _getFingerprint() {
  const fingerprint = (function (window, screen, navigator) {
    // https://github.com/darkskyapp/string-hash
    function checksum(str: string) {
      let hash = 5381,
        i = str.length;

      while (i--) hash = (hash * 33) ^ str.charCodeAt(i);

      return hash >>> 0;
    }

    // http://stackoverflow.com/a/4167870/1250044
    function map(
      arr: string | any[] | PluginArray,
      fn: { (plugin: any): string; (mime: any): string; (arg0: any): any }
    ) {
      let i = 0,
        // eslint-disable-next-line prefer-const
        len = arr.length,
        // eslint-disable-next-line prefer-const
        ret = [];
      while (i < len) {
        ret[i] = fn(arr[i++]);
      }
      return ret;
    }

    return checksum(
      [
        navigator.userAgent,
        [screen.height, screen.width, screen.colorDepth].join("x"),
        new Date().getTimezoneOffset(),
        !!window.sessionStorage,
        !!window.localStorage,
        map(navigator.plugins, function (plugin: any) {
          return [
            plugin.name,
            plugin.description,
            map(plugin, function (mime: { type: any; suffixes: any }) {
              return [mime.type, mime.suffixes].join("~");
            }).join(","),
          ].join("::");
        }).join(";"),
      ].join("###")
    );
  })(window, screen, navigator);

  return fingerprint;
}
