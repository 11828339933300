import axios from "@/http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  fetchSuppliersList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      if (data) {
        Object.keys(data).forEach((key: string) => {
          formData.append(key, data[key]);
        });
      }

      commit("SET_LOADING", true);
      try {
        const response = await axios.get("/api/v1/admin/suppliers/", {
          params: data || {},
        });
        const json = response.data;

        if (json.response == 1001) {
          commit("setSuppliers", json.suppliers);
          commit("setSuppliersFilters", json.filters);
          resolve(response.data);
        } else {
          reject(response.data.message);
        }
      } catch (e) {
        try {
          reject(e.response.data.message);
        } catch (e) {
          reject(e.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  fetchSupplier({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      if (data) {
        Object.keys(data).forEach((key: string) => {
          formData.append(key, data[key]);
        });
      }

      commit("SET_LOADING", true);
      try {
        const response = await axios.get("/api/v1/admin/suppliers/details/", {
          params: data || {},
        });
        const json = response.data;

        if (json.response == 1001) {
          commit("setSupplier", json.supplier);
          resolve(response.data);
        } else {
          reject(response.data.message);
        }
      } catch (e) {
        try {
          reject(e.response.data.message);
        } catch (e) {
          reject(e.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  updateSupplier({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      if (data) {
        Object.keys(data).forEach((key: string) => {
          formData.append(key, data[key]);
        });
      }

      commit("SET_LOADING", true);
      try {
        const response = await axios.put(
          "/api/v1/admin/suppliers/update/",
          formData
        );
        const json = response.data;

        if (json.response == 1001) {
          resolve(response.data);
        } else {
          reject(response.data.message);
        }
      } catch (e) {
        try {
          reject(e.response.data.message);
        } catch (e) {
          reject(e.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  addNewSupplier({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      if (data) {
        Object.keys(data).forEach((key: string) => {
          formData.append(key, data[key]);
        });
      }

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          "/api/v1/admin/suppliers/add/",
          formData
        );
        const json = response.data;

        if (json.response == 1001) {
          resolve(response.data);
        } else {
          reject(response.data.message);
        }
      } catch (e) {
        try {
          reject(e.response.data.message);
        } catch (e) {
          reject(e.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
};
const suppliersStore: Module<any, any> = {
  state: () => ({
    suppliers: [],
    supplier: {},
    suppliersFilters: {},
  }),
  mutations: {
    setSuppliers(state, suppliers) {
      state.suppliers = suppliers;
    },
    setSupplier(state, supplier) {
      state.supplier = supplier;
    },
    setSuppliersFilters(state, filters) {
      state.suppliersFilters = filters;
    },
  },
  actions,
  getters: {
    suppliers(state) {
      return state.suppliers;
    },
    suppliersFilters(state) {
      return state.suppliersFilters;
    },
  },
};

export default suppliersStore;
