import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store/index";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "App",
    component: () =>
      import(/* webpackChunkName: "base" */ "../layouts/Main.vue"),
    children: [
      // Products
      {
        path: "/",
        redirect: "/products",
      },
      {
        path: "/products",
        name: "products",
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "products" */ "../views/home/ProductsHome.vue"
          ),
      },
      {
        path: "/product/add",
        name: "add-product",
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "products" */ "../views/home/AddProduct.vue"
          ),
      },
      {
        path: "/product/:productId",
        name: "edit-product",
        props: true,
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "products" */ "../views/home/EditProduct.vue"
          ),
      },
      // Suppliers
      {
        path: "/suppliers",
        name: "suppliers",
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "suppliers" */ "../views/suppliers/SuppliersList.vue"
          ),
      },
      {
        path: "/supplier/add",
        name: "add-supplier",
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "suppliers" */ "../views/suppliers/AddEditSupplier.vue"
          ),
      },
      {
        path: "/supplier/:supplierId",
        name: "edit-supplier",
        props: true,
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "suppliers" */ "../views/suppliers/AddEditSupplier.vue"
          ),
      },
      // Inventory
      {
        path: "/inventory",
        name: "inventory",
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "inventory" */ "../views/inventory/InventoryList.vue"
          ),
      },
      {
        path: "/inventory/add",
        name: "add-inventory",
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "inventory" */ "../views/inventory/AddInventory.vue"
          ),
      },
      // Customers
      {
        path: "/customers",
        name: "customers",
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "customers" */ "../views/customers/CustomersList.vue"
          ),
      },
      {
        path: "/customer/add",
        name: "add-customer",
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "customers" */ "../views/customers/AddEditCustomer.vue"
          ),
      },
      {
        path: "/customer/:customerId",
        name: "edit-customer",
        props: true,
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "customers" */ "../views/customers/AddEditCustomer.vue"
          ),
      },
      // Wallet
      {
        path: "/wallet",
        name: "wallet-history",
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "wallet" */ "../views/wallet/WalletList.vue"
          ),
      },
      // Reports
      {
        path: "/reports/sales",
        name: "sales-reports",
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "../views/reports/SalesReport.vue"
          ),
      },
      {
        path: "/reports/recharge-summary",
        name: "recharge-summary",
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "../views/reports/RechargeSummaryReport.vue"
          ),
      },
      // Reclamations
      {
        path: "/reclamation",
        name: "reclamation",
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "reclamation" */ "../views/reclamations/ReclamationsList.vue"
          ),
      },
    ],
  },
  {
    path: "/",
    name: "Auth",
    component: () =>
      import(/* webpackChunkName: "base" */ "../layouts/MainNoAuth.vue"),
    children: [
      {
        path: "/login",
        name: "Login",
        meta: { isLoginPage: true },
        component: () =>
          import(/* webpackChunkName: "auth" */ "../views/auth/Login.vue"),
      },
    ],
  },
  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.state.isLoggedIn) next({ name: "Login" });
  else if (to.meta.isLoginPage && store.state.isLoggedIn)
    next({ name: "Home" });
  else next();
});

export default router;
