function createFilters(app: any) {
  app.config.globalProperties.$filters = {
    currencyUSD(value: any) {
      return "$" + value;
    },
    euroFormat(value: any) {
      const formatter = new Intl.NumberFormat("en-de", {
        style: "currency",
        currency: "EUR",
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(value);
    },
    downloadCSV(data: any, filename: string) {
      const hiddenElement = document.createElement("a");
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(data)}`;
      hiddenElement.target = "_blank";
      hiddenElement.download = filename;
      hiddenElement.click();
    },
    dateFormat(value: any) {
      if (!value) {
        return "NA";
      }
      try {
        const options1 = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          timeZone: "UTC",
        };
        const date = new Date(value);
        return date.toLocaleString("de", { timeZone: "Europe/Berlin" });
        // const dateTimeFormat = new Intl.DateTimeFormat('de', options1)
        // return dateTimeFormat.format(date)
      } catch (e) {
        return "NA";
      }
    },
    onlyDateFormat(value: any) {
      if (!value) {
        return "NA";
      }
      try {
        const options1 = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          timeZone: "UTC",
        };
        const date = new Date(value);
        return date.toLocaleDateString("de");
        const dateTimeFormat = new Intl.DateTimeFormat("de", options1);
        return dateTimeFormat.format(date);
      } catch (e) {
        return "NA";
      }
    },
  };
}

export default createFilters;
